<template>
  <div
    class="tc-system_setup"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <el-row>
      <el-col :span="20">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="230px"
          size="mini"
        >
          <el-form-item label="是否开启用户编号自生成：">
            <el-radio-group v-model="form.self_motion_create">
              <el-radio label="1">开启</el-radio>
              <el-radio label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="form.self_motion_create == 1">
            <el-form-item label="用户编号字符类型：">
              <el-radio-group v-model="form.encoding_type">
                <el-radio label="1">日期</el-radio>
                <el-radio label="2">纯数字</el-radio>
                <el-radio label="3">数字字母组合</el-radio>
                <el-radio label="4">随机生成</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-row v-if="form.encoding_type == 1">
              <el-col>
                <el-form-item label="按日期生成：">
                  <el-radio-group v-model="form.date">
                    <el-radio label="0"
                      >按年月日时分秒自动生成(例：20200522145943)</el-radio
                    >
                    <el-radio label="1"
                      >按年月日自动生成(例：20200522001)</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="form.encoding_type == 2">
              <el-col :span="8">
                <el-form-item label="按数字生成的初始数值：" prop="initial">
                  <el-input
                    v-model="form.initial"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="12"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div
                  style="
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #606266;
                    margin-left: 20px;
                  "
                >
                  (例：1000，每增加一名测试者数值自动加1)
                </div>
              </el-col>
            </el-row>

            <el-row v-if="form.encoding_type == 3">
              <el-col>
                <el-form-item label="按数字字母组合生成：">
                  <el-col :span="1.5">
                    <span>前缀为</span>
                  </el-col>
                  <el-col :span="3">
                    <el-input
                      v-model="form.prefix"
                      maxlength="8"
                      show-word-limit
                    ></el-input>
                  </el-col>
                  <el-col :span="2">
                    <span>可为空</span>
                  </el-col>
                  <el-col :span="1.5">
                    <span>初始数值</span>
                  </el-col>
                  <el-col :span="3">
                    <el-input
                      v-model="form.initial_value"
                      onkeyup="value=value.replace(/[^\d]/g,'') "
                      maxlength="8"
                      show-word-limit
                    ></el-input>
                  </el-col>
                  <el-col :span="4">
                    <span>(例：1000，自动加1)</span>
                  </el-col>
                  <el-col :span="1.5">
                    <span>后缀为</span>
                  </el-col>
                  <el-col :span="3">
                    <el-input
                      v-model="form.suffix"
                      maxlength="8"
                      show-word-limit
                    ></el-input>
                  </el-col>
                  <el-col :span="1.5">
                    <span>可为空</span>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="form.encoding_type == 4">
              <el-col>
                <el-form-item label="按随机生成：">
                  <el-radio-group v-model="form.rand">
                    <el-radio label="rand_number">随机生成数字</el-radio>
                    <el-radio label="rand_letter">随机生成字母</el-radio>
                    <el-radio label="rand_number_letter"
                      >随机生成字母，限制位数</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                  label="限制随机生成位数:"
                  v-if="form.rand == 'rand_number_letter'"
                >
                  <el-select v-model="form.count" placeholder="请选择">
                    <el-option :label="6" :value="6"></el-option>
                    <el-option :label="7" :value="7"></el-option>
                    <el-option :label="8" :value="8"></el-option>
                    <el-option :label="9" :value="9"></el-option>
                    <el-option :label="10" :value="10"></el-option>
                    <el-option :label="11" :value="11"></el-option>
                    <el-option :label="12" :value="12"></el-option>
                    <el-option :label="13" :value="13"></el-option>
                    <el-option :label="14" :value="14"></el-option>
                    <!-- <el-option :label="15" :value="15"></el-option>
                    <el-option :label="16" :value="16"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="修改编号页面是否显示：">
              <el-radio-group v-model="form.update_mark">
                <el-radio label="1">显示</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="添加编号页面是否显示：">
              <el-radio-group v-model="form.add_mark">
                <el-radio label="1">显示</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否自动生成密码：">
              <el-radio-group v-model="form.self_password">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="密码生成设置：">
              <el-radio-group v-model="form.password_rules">
                <el-radio label="0">和用户编号相同</el-radio>
                <el-radio label="1">统一固定密码</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="统一固定密码："
                  v-if="form.password_rules == 1"
                >
                  <el-input
                    type="text"
                    v-model="form.pwd_value"
                    placeholder="不填写，默认密码为：123456"
                    maxlength="16"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="添加密码页面是否显示：">
              <el-radio-group v-model="form.add_pwd">
                <el-radio label="1">显示</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="修改密码页面是否显示：">
              <el-radio-group v-model="form.update_pwd">
                <el-radio label="1">显示</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" style="margin-top: 30px">
      <el-button type="primary" @click="save">保存设置</el-button>
    </el-row>
  </div>
</template>
<script>
import { selectConfig, updateConfig } from "@/api/comm.js";
import { clearTrash } from "@/api/login.js";
export default {
  inject: ["reload"],
  data() {
    return {
      loadingText: "页面加载中",
      loading: true,
      form: {
        password_rules: 0,
        self_motion_create: 1,
        self_motion_create: 1,
      },
      rules: {
        initial: [
          {
            min: 4,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    // 页面初始化
    this.init();
  },
  methods: {
    // 页面数据初始化
    init() {
      selectConfig({ config_type: "system_register" }).then((res) => {
        if (res.code == 400200) {
          this.form = res.data;
          this.loading = false;
        }
      });
    },
    //  保存
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingText = "正在更新系统配置";
          this.loading = true;
          updateConfig(this.form).then((res) => {
            if (res.code == 400200) {
              this.loading = false;
              this.$message({
                message: "保存成功！",
                type: "success",
              });
              // this.reload();
              this.init();
              clearTrash();
            }
          });
        } else {
          this.$message({
            message: "填写有误，请检查",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.tc-system_setup {
  margin-top: 30px;
  .el-form-item {
    margin-bottom: 8px;
    .el-input {
      .el-input__inner {
        &::placeholder {
          color: #007491;
        }
      }
    }
  }
}
</style>